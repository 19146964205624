.calculator {
    width: 20rem;
    border: 1px solid #01b0d3;
    padding: 2rem;
    height: 15rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.calculator dl {
    margin: 0;
}

.calculator dl div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.calculator dl div dt, dl {
    padding: 0 0.5rem;
}
