.app {
    text-align: center;
    margin-top: 5%;
    font-size: 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.app h1 {
    margin-bottom: 4rem;
}

.app aside {
    font-size: 1rem;
    margin: 1rem 0;
    opacity: 0.75;
    max-width: 30rem;
}

.app aside p {
    margin: 1rem 0;
}

.app form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.app input, select {
    font-size: 1.5rem;
    margin-left: 1rem;
    width: 5rem;
}

.app button, a.as-btn {
    border: none;
    background-color: #01b0d3;
    color: #fff;
    font-size: 1.5rem;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

a.as-btn:hover {
    text-decoration: none;
}

.app button:hover, a.as-btn:hover {
    background-color: #015869;
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.app nav ul li button {
    background-color: transparent;
    font-size: 1.25rem;
    border: 1px solid #01b0d3;
    border-radius: 0;
}

.app nav ul li button.current {
    background-color: #015869;
}

.app .buy-beer-wrapper a {
    font-size: 1rem;
}
